<template>
  <div class="relative">
    <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
      <div class="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
        <div class="mx-auto max-w-2xl lg:mx-0">
          <img src="../assets/PythonPat-logo.svg" alt="Python pat" class="w-3/4 hidden sm:block" />
          <div class="hidden sm:mt-32 sm:flex lg:mt-16">
            <div class="relative rounded-full px-3 py-1 text-sm leading-6 text-patLightest ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            </div>
          </div>
          <h1 class="mt-24 text-4xl font-bold tracking-tight text-patLightest sm:mt-10 sm:text-6xl">Where <span class="text-patBrand">Coding</span> Meets <span class="text-patBrand">Creativity</span></h1>
          <p class="mt-6 text-lg leading-8 text-patLightest">For the past 18 years, I have immersed myself in the world of development,
            finding immense joy in sharing my experiences and imparting knowledge to the community.</p>
          <div class="mt-10 flex items-center gap-x-6">
            <a href="#" class="rounded-md bg-patBrand px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-patDark focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Get started</a>
            <a href="#" class="text-sm font-semibold leading-6 text-patLightest">Learn more <span aria-hidden="true">→</span></a>
          </div>
        </div>
      </div>
      <div class="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
        <img class="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full" src="../assets/images/home.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup>
</script>