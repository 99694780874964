<template>
  <div class="overflow-hidden py-32">
    <div class="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
      <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
        <div class="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
          <h2 class="text-3xl font-bold tracking-tight text-patBrand sm:text-4xl">About me</h2>
          <p class="mt-6 text-xl leading-8 text-patLightest">For the past 18 years, I have immersed myself in the world of development, finding immense joy in sharing my experiences and imparting knowledge to the community.</p>
          <p class="mt-6 text-base leading-7 text-patLightest">Throughout my journey, I have explored numerous frameworks and languages, and among them, Flask and Vue.js have stood out as the pinnacle of effectiveness in the development process. These two frameworks have proven to be the most powerful and efficient tools I've encountered, allowing me to unleash my creativity and build exceptional applications.</p>
          <p class="mt-6 text-base leading-6 text-patLightest">I have opted for MySQL due to its user-friendly nature and its efficient data storage capabilities. I consider it to be an elegant solution for storing diverse data types, and its seamless integration with Flask further enhances its appeal. Therefore, I invite you to join me on this exciting journey of learning development, where we can embrace the process of becoming skilled full-stack engineers together. Let's dive into the world of technology and unlock endless possibilities!</p>
        </div>
        <div class="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
          <div class="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
            <img src="../assets/images/about5.png" alt="" class="aspect-[7/5] w-[37rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
          </div>
          <div class="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
            <div class="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
              <img src="../assets/images/about6.png" alt="" class="aspect-[4/3] w-[24rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
              <img src="../assets/images/about7.png" alt="" class="aspect-[7/5] w-[37rem] max-w-none flex-none rounded-2xl bg-gray-50 object-cover" />
            </div>
            <div class="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
              <img src="../assets/images/about8.png" alt="" class="aspect-[4/3] w-[24rem] max-w-none rounded-2xl bg-gray-50 object-cover" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
</script>