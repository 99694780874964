import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
     meta: {
            title: 'Python Pat - Welcome',
            metaTags: [
                {
                    name: 'description',
                    content: 'Welcome to Python Pat - Your ultimate resource for learning Python. Discover tutorials, articles, and resources to enhance your coding skills.'
                }
            ]
        }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
     meta: {
            title: 'Python Pat - About',
            metaTags: [
                {
                    name: 'description',
                    content: 'Learn about Python Pat - My mission, vision, and the resources we provide to help you master Python programming. Explore what we do and join our community.'
                }
            ]
        }
  },
  {
    path: '/resume',
    name: 'resume',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResumeView.vue'),
     meta: {
            title: 'Python Pat - Resume',
            metaTags: [
                {
                    name: 'description',
                    content: 'Discover my professional journey and job history. View my resume to learn about my skills, experiences, and career achievements in Python development.'
                }
            ]
        }
  },
  {
    path: '/articles',
    name: 'articles',
    component: () => import(/* webpackChunkName: "about" */ '../views/BlogsView.vue'),
     meta: {
            title: 'Python Pat - Articles',
            metaTags: [
                {
                    name: 'description',
                    content: 'Explore insightful articles and tutorials from Python Pat. Stay updated with the latest trends, tips, and tricks in Python programming.'
                }
            ]
        }
  },
  {
        path: "/article/:slug",
        name: "article",
        props: true,
        component: () =>
            import("../views/BlogView.vue"),
        meta: {
            title: 'Python Pat - Article',
            metaTags: [
                {
                    name: 'description',
                    content: 'Explore insightful articles and tutorials from Python Pat. Stay updated with the latest trends, tips, and tricks in Python programming.'
                }
            ]
        }
    },
    {
        path: "/prompt-engineering",
        name: "prompt_engineering",
        props: true,
        component: () =>
            import("../views/PromptEngineer.vue"),
        meta: {
            title: 'Python Pat - Prompt Engineer',
            metaTags: [
                {
                    name: 'description',
                    content: 'Unlock the full potential of AI with expert prompt engineering services, starting at £50, from a certified developer in ChatGPT Prompt Engineering from deeplearning.ai.'
                }
            ]
        }
    },
    {
        path: "/music-corner",
        name: "music",
        props: true,
        component: () =>
            import("../views/MusicView.vue"),
        meta: {
            title: 'Python Pat - Music Corner',
            metaTags: [
                {
                    name: 'description',
                    content: 'Discover curated Spotify playlists for developers. ' +
                        'Enhance your coding sessions with focus beats, upbeat tracks, ' +
                        'and chill vibes. Tune in and code on!'
                }
            ]
        }
    },
    {
        path: "/contact-pat",
        name: "contact",
        props: true,
        component: () =>
            import("../views/ContactView.vue"),
        meta: {
            title: 'Python Pat - Contact Pat',
            metaTags: [
                {
                    name: 'description',
                    content: "I'd love to collaborate with you! Whether you're " +
                        "looking for cutting-edge web development, innovative software " +
                        "solutions, or expert prompt engineering, I'm here to help bring your vision to life"
                }
            ]
        }
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
    const nearestWithTitle        = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const nearestWithMeta         = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    if(nearestWithTitle) {
      document.title = nearestWithTitle.meta.title;
    } else if(previousNearestWithMeta) {
      document.title = previousNearestWithMeta.meta.title;
    }

    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    if(!nearestWithMeta) return next();

    nearestWithMeta.meta.metaTags.map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

    next();
  });


export default router
