<template>
  <div class="py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-2xl lg:max-w-4xl">
        <h2 class="text-3xl font-bold tracking-tight text-patBrand sm:text-4xl">How to Code with Python Pat:</h2>
        <p class="mt-2 text-lg leading-8 text-white">Blog and Tutorials for Aspiring Developers 🚀</p>
        <div class="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
          <article v-for="post in posts" :key="post.id" class="relative isolate flex flex-col gap-8 lg:flex-row">
            <div class="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
              <img :src="'https://pythonpat.ams3.cdn.digitaloceanspaces.com/pythonpat/test/' + post.image" alt="" class="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover" />
              <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div>
              <div class="flex items-center gap-x-4 text-xs">
                <time :datetime="post.datetime" class="text-patLight">{{ $moment(post.date_created).format("MMMM Do YYYY, h:mm:ss a") }}</time>
                <a :href="post.category" class="relative z-10 rounded-full bg-patBrand px-3 py-1.5 font-medium text-patDark hover:bg-patDark hover:text-patLight">{{ post.category }}</a>
              </div>
              <div class="group relative max-w-xl">
                <h3 class="mt-3 text-lg font-semibold leading-6 text-patBrand group-hover:text-patDark">
                  <router-link :to="'/article/' + post.slug">
                    <span class="absolute inset-0" />
                    {{ post.title }}
                  </router-link>
                </h3>
                <p class="mt-5 text-sm leading-6 text-patLight line-clamp-3">{{ stripTags(post.body) }}</p>
              </div>
              <div class="mt-6 flex border-t border-gray-900/5 pt-6">
                <div class="relative flex items-center gap-x-4">
                  <img src="../assets/logonav.png" alt="" class="h-10 w-10 rounded-full bg-gray-50" />
                  <div class="text-sm leading-6">
                    <p class="font-semibold text-patLight">
                      <span>
                        <span class="absolute inset-0" />
                        {{ post.author }}
                      </span>
                    </p>
                    <p class="text-gray-600"></p>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      posts: [],
      api: process.env.VUE_APP_API_ENDPOINT,
      url: ""
    }
  },
  methods: {
    getArticles() {
      this.axios.get(this.api + '/get_three').then(response => {
        this.posts = response.data.data
      })
    },
    stripTags(original) {
      return original.replace(/(<([^>]+)>)/gi, "");
    }
  },
  created() {
    this.getArticles()
  }
}

</script>