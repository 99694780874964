<template>
  <HeroSection></HeroSection>
  <about-section></about-section>
  <BlogThree></BlogThree>
</template>

<script>
import HeroSection from "@/components/HeroSection.vue";
import AboutSection from "@/components/AboutSection.vue";
import BlogThree from "@/components/BlogThree.vue";
export default {
  name: 'HomeView',
  components: {
    HeroSection,
    AboutSection,
    BlogThree
  }
}
</script>
